
import * as events from "../Events";
const initialState = {
  aiInsights:null,
  users: null,
  contacts: null,
  user_details: null,
  notes: null,
  stats: null,
  calls: null,
  followupUsers: null,
  

};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case events.GET_ALL_USERS:
      return {
        ...state,
        users: action.payload
      };
    case events.GET_ALL_PRIVATE_CONTACTS:
      return {
        ...state,
        contacts:action.payload
       
      }
    case events.GET_USER_DETAILS:
      return {
        ...state,
        user_details: action.payload
      }
    case events.GET_ALL_NOTES:
      return {
        ...state,
        notes: action.payload
      }
    case events.STATS:
      return {
        ...state,
        stats: action.payload
      }
    case events.GET_ALL_CALLS:
      return {
        ...state,
        calls: action.payload
      }
    case events.FOLLOW_UP_USERS:
      return {
        ...state,
        followupUsers: action.payload
      }
     
    case events.FOLLOW_UP_CALL_STATUS:
      return {
        ...state,
        followupUsers:state.followupUsers&& {
          ...state.followupUsers, results: state.followupUsers.results.map(val => {
            if (val.id === action.payload.id) {
              return action.payload
            } else {
              return val
            }
          })
        }
      }
    case events.CALL_STATUS:      
      return {
        ...state,
        contacts:state.contacts&& {
          ...state.contacts, results: state.contacts.results.map(val => {
            if (val.id === action.payload.id) {
              return action.payload
            } else {
              return val
            }
          })
        }
      }
    case events.ADD_NOTES:
      if (action.payload.tag === "call") {
        return {
          ...state,
          followupUsers: {
            ...state.followupUsers, results: state?.followupUsers?.results.map(val => {
              if (val.user_details_id === action.payload.user_details) {
                return {...val,status:"completed"}
              } else {
                return val
              }
            })
          }
        }
      } else {
        return state
      }
      
    default:
      return state;
  }
};
