import React,{useEffect} from 'react'
import Stepper from '../../components/stepper/Stepper'
import TickIcon from '../../icons/TickIcon'
import { Outlet } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getUserDetails } from '../../redux/actions/usersactions'
import { useParams,useNavigate,useLocation } from 'react-router-dom'
import BackIcon from '../../icons/BackIcon';
import { usePath } from '../../states/PathContext'

const UserDetails = () => { 
  const token = localStorage.getItem("token")
  const { previouspath ,path}=usePath();
  const {id}=useParams();
  const navigate=useNavigate();
  const location=useLocation()
  const dispatch=useDispatch()
  //for fetching existing details
  const fetchUSerDetails = async () => {
    try {
      await dispatch(getUserDetails(id))
    } catch (err) {
      console.log(err)

    }
  }

  useEffect(() => {
    if(id){
      fetchUSerDetails()
    }
    let from = location.state?.from;
      previouspath(from);
  
  
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0);
   
  }, [location.pathname]);
  
if (!token) {
  navigate("/")
}

const handleStepPress=(type)=>{

if(type==="basicinfo"){
  navigate(`/user/add/${id}`, { state: { from: path } })
}else{
  navigate(`/user/add/${id}/${type}`, { state: { from: path } })
}
}
  return (
    <div>
      <div className="flex justify-between border border-b-[1px] border-gray-300 h-68-scale-mob lg:h-68-scale-924 items-center pl-40-scale-mob lg:pl-40-scale-924 pr-25-scale-mob lg:pr-25-scale-924"  >
        <p className='text-18-scale-mob lg:text-18-scale-924 font-[500] leading-24-scale-924 tracking-tight'>Add care receiver details</p>
        <div className="flex items-center gap-x-[10px]">
          <TickIcon />
          <p className='text-18-scale-mob lg:text-18-scale-924 tracking-tight'>Your form will be autosaved</p>
        </div>
      </div>
      {(location.pathname===`/user/add/${id}`||location.pathname===`/user/add/${id}/summary`)&&<div onClick={()=>{navigate("/users")}} className="flex items-center font-[600] text-20-scale-mob md:text-20-scale-924 gap-[10px] md:px-20-scale-924 px-20-scale-924 md:pt-20-scale-924 pt-20-scale-924 cursor-pointer">
        <BackIcon/>
        Back to dashboard
      </div>}
      <div className='flex justify-center lg:pt-34-scale-924'>
        <Stepper  handleStepPress={handleStepPress}/>
      </div>
     
        {/* <BasicInfo /> */}
        <Outlet />
      
    
    </div>
  )
}

export default UserDetails