import React, { useState, useEffect } from 'react'
import GlobalInput from '../../components/GlobalInput';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

//css
import "./form.css"
import GlobalCalendar from '../../components/calendar/GlobalCalendar';
import GLobalSelect from '../../components/GlobalSelect';
import GlobalTextArea from '../../components/GlobalTextArea';
import ContactsForm from './ContactsForm';
import FormFooter from './FormFooter';
import { editUserDeatils, getUserDetails } from '../../redux/actions/usersactions';
import moment from 'moment';
import { getCities, getProvinces } from '../../redux/actions/commonActions';
import { toast } from 'react-toastify';
import { formatString } from '../../methods';
import { usePath } from '../../states/PathContext';

const BasicInfo = () => {
  //button loader
  const [inputValue, setInputValue] = useState(moment(new Date()).format("DD/MM/YYYY"))
  const { path } = usePath();
  const [refresh, setRefersh] = useState(false)
  const [loading, setLoading] = useState(false)
  const formData = useSelector((state) => state.users.user_details);
  const provinces = useSelector((state) => state.common.provinces);
  const cities = useSelector((state) => state.common.cities);
  const params = useParams();
  const { id } = params;
  const [selectedDate, setSelectedDate] = useState(formData ? formData.selectedDate : new Date());
  const dispatch = useDispatch()
  const methods = useForm({ mode: "all" });
  const navigate = useNavigate()
  const { control, formState: { errors, dirtyFields }, setValue, getValues } = methods;
  const [contactArr, setContactArr] = useState([{ is_agreed: false, id: 1, source: "application" }]);
  //options
  const familytypeOptions = [{ label: "Living alone", value: "alone" }, { label: "Living with Spouse", value: "spouse" }, { label: "Living with caretaker", value: "caretaker" }, { label: "Living with children", value: "children" }]
  const relationshipOptions = [{ label: "Daughter/Son", value: "daughter/son" }, { label: "Spouse", value: "spouse" }, { label: "Sibling", value: "sibling" }, { label: "Other", value: "other" }]
  const defaultProvinceOptions = provinces ? provinces.map(val => {
    return ({
      value: val.id.toString(),
      label: val.name
    })
  }) : []
  const defaultCitiesOptions = cities ? cities.map(val => {
    return ({
      value: val.id.toString(),
      label: val.name
    })
  }) : []
  //submit function
  const onSubmit = async (data) => {
    setLoading(true)
    try {
      const emergency_contacts = contactArr.map(val => {
        const result =
        {
          relationship: data[`relationship_${val.id}`] && data[`relationship_${val.id}`].value,
          "name": data[`name_${val.id}`],
          "contact_number": data[`contact_number_${val.id}`],
          // contact_number:"",
          is_agreed: val.is_agreed
        }
        if (val.source === "database") {
          result.id = val.id
        }
        return result;
      })
      const family_details = {
        family_type: data.family_type && data.family_type.value,

      }
      if (data.family_type && data.family_type.value !== "alone") {
        family_details.spouse_name = data.spouse_name;
        family_details.spouse_age = data.spouse_age ? parseInt(data.spouse_age) : null;
        family_details.other_family_information = data.other_family_information;
      }
      else {
        family_details.spouse_name = null
        family_details.spouse_age = null;
        family_details.other_family_information = null;
      }
      const reqData = {
        full_name: data.full_name,
        gender: data.gender,
        phone_number: `91${data.phone_number}`,
        dob: moment(selectedDate).format("YYYY-MM-DD"),
        // address: data.address,
        city: data.city && data.city.value,
        province: data.province && data.province.value,
        family_details,
        emergency_contacts,

      }
      await dispatch(editUserDeatils(id, reqData));
      await dispatch(getUserDetails(id))
      // navigate(`medicalinfo`, { state: { from: path } })
      navigate(`hobbiesinfo`, { state: { from: path } })
    } catch (err) {
      console.log(err);
      toast.error(err.message)
    }
    setLoading(false)
  }
  //calendar
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setInputValue(moment(date).format("DD/MM/YYYY"))

  };
  //defaultvalues in form
  useEffect(() => {
    if (formData && formData.id === parseInt(id)) {
      const { family_details, emergency_contacts, ...restFormData } = formData;
      emergency_contacts && emergency_contacts.length > 0 && setContactArr(emergency_contacts && emergency_contacts.map(val => ({ is_agreed: val.is_agreed, id: val.id, source: "database" })));
      formData.province && handleChangeProvince(formData.province);

      if (family_details && emergency_contacts) {
        for (let key of Object.keys({ ...restFormData, ...family_details, ...emergency_contacts })) {
          const value = key in restFormData ? restFormData[key] : family_details[key];
          setValue(key, value);
        }
      }
      else {
        for (let key of Object.keys(restFormData)) {
          if (key !== "phone_number") {
            const value = restFormData[key];
            setValue(key, value);
          }
        }

      }
      family_details && setValue("family_type", { value: family_details.family_type, label: familytypeOptions.find(option => option.value === family_details.family_type)?.label })
      formData.province && setValue("province", { value: formData.province, label: formData.province_name })
      formData.province && setValue("city", { value: formData.city, label: formData.city_name })
      if (emergency_contacts) {
        for (let contact of emergency_contacts) {
          const selectedOption = relationshipOptions.find(option => option.value === contact.relationship);
          setValue(`relationship_${contact.id}`, { value: contact.relationship, label: selectedOption && selectedOption.label });
          setValue(`name_${contact.id}`, contact.name);
          setValue(`contact_number_${contact.id}`, contact.contact_number);
        }
      }

      if (formData.dob) {
        setSelectedDate(moment.utc(formData.dob));
        setInputValue(moment(formData.dob).format('DD/MM/YYYY'))
        // moment(props.selectedDate).format('DD/MM/YYYY')
      }
      const phone_no = restFormData.phone_number;
      const cleanedPhoneNo = phone_no.replace(/^91/, '');

      setValue("phone_number", cleanedPhoneNo)

    }
  }, [formData])
  // useEffect(() => {
  //   formData && defaultCitiesOptions && setValue("city", { value: formData.city, label: defaultCitiesOptions.find(option => option.value === formData.city)?.label })
  // }, [formData])
  //defaultValues for provinces
  const fetchProvinces = async () => {
    try {
      await dispatch(getProvinces())
    } catch (err) {
      console.log(err.message)
    }
  }
  useEffect(() => {
    fetchProvinces()
  }, [])
  //defaultValues for cities
  const handleChangeProvince = async (id) => {
    try {
      await dispatch(getCities(id))
    } catch (err) {
      console.log(err)
    }
  }

  //is agreed checkbox
  const handleChangeCheckbox = (id) => {
    setContactArr(contactArr.map(val =>
      val.id === id ? { ...val, is_agreed: !val.is_agreed } : val
    ));

  }

  const onCloseForm = async () => {
    const originalObject = dirtyFields;
    
    // Extract normal fields
       const normalFields = Object.keys(originalObject).reduce((acc, key) => {
      if (!['relationship', 'spouse', 'name', 'contact_number', 'other_family_information', 'family_type'].some(substr => key.includes(substr) && key !== "full_name")) {
        acc[key] = (key === "province" || key === "city") ? getValues(key)?.value : getValues(key);
      }
      return acc;
    }, {});


    const emergency_contacts = contactArr.map(val => {
      const result =
      {
        relationship: getValues(`relationship_${val.id}`) && getValues(`relationship_${val.id}`).value,
        "name": getValues(`name_${val.id}`),
        "contact_number": getValues(`contact_number_${val.id}`),
        // contact_number:"",
        is_agreed: val.is_agreed
      }
      if (val.source === "database") {
        result.id = val.id
      }
      return result;
    })

    const family_details = {
      family_type: getValues("family_type") && getValues("family_type").value,

    }
    if (getValues("family_type") && getValues("family_type").value !== "alone") {
      family_details.spouse_name = getValues("spouse_name");
      family_details.spouse_age = getValues("spouse_age") ? parseInt(getValues("spouse_age")) : null;
      family_details.other_family_information = getValues("other_family_information");
    }
    else {
      family_details.spouse_name = null
      family_details.spouse_age = null;
      family_details.other_family_information = null;
    }

    // Construct the request data
    const reqData = {};
    if (Object.keys(family_details).length) reqData.family_details = family_details;
    if (emergency_contacts.length) reqData.emergency_contacts = emergency_contacts;

    try {
      await dispatch(editUserDeatils(id, {...normalFields,...reqData,phone_number:`91${getValues("phone_number")}`, dob: moment(selectedDate).format("YYYY-MM-DD") }));
      // navigate("/users")
      path === "/users" ? navigate(`/users`) : navigate("/usermanagement")
    } catch (err) {
      console.error(err);
      toast.error(err.message)
    }
  };
  //contact del
  const handleContactDel = (id) => {
    setContactArr(contactArr.filter(val => val.id !== id))
  }
  const handleDateInputChange = (e) => {
    // console.log(e.target.value)
    setInputValue(e.target.value)
    // console.log(moment(e.target.value).format("YYYY-MM-DD"))
    if (moment(e.target.value).format("YYYY-MM-DD") !== "Invalid date") {
      setSelectedDate(moment.utc(e.target.value))
    }

  }
  return (
    <>
      <div className="px-19-scale-mob md:px-19-scale-924 pt-50-scale-mob md:pt-50-scale-924 md:w-[100%]">

        <div className="px-20-scale-mob md:px-20-scale-924 w-[100%] card pt-20-scale-mob md:pt-20-scale-924 pb-30-scale-mob md:pb-30-scale-924 flex flex-col md:w-[80%]  mx-auto">
          <p className="text-30-scale-mob md:text-22-scale-924 font-[600] ">Personal Information</p>
          <p className="text-16-scale-mob md:text-18-scale-924 font-[600] tracking-tight ">Senior unique id - {id} </p>
          <div className="flex flex-wrap w-[100%] justify-between form-fields items-center">
            <div className="w-[100%] md:w-[47%]">
              <GlobalInput name="full_name" label="Full Name" control={control} labelStyles={"text-16-scale-mob md:text-16-scale-924 font-[600]"}  />
            </div>
            <div className="w-[100%] md:w-[47%] ">
              <GlobalInput name="gender" label="Gender" control={control} labelStyles={"text-16-scale-mob md:text-16-scale-924 font-[600]"} className={"h-50-scale-924-mob md:!h-45-scale-924"} />
            </div>
            <div className="w-[100%] md:w-[47%]">
              <GlobalCalendar name="dob" label="Date Of Birth" control={control} labelStyles={"text-16-scale-mob md:text-16-scale-924 font-[600] "} className={"h-50-scale-924-mob md:!h-45-scale-924"}  selectedDate={selectedDate} handleDateChange={handleDateChange}
                handleInputChange={handleDateInputChange} inputValue={inputValue} type="dob"

              />
            </div>
            <div className="w-[100%] md:w-[47%]">
              <GlobalInput name="phone_number" label="Mobile Number" control={control} labelStyles={"text-16-scale-mob md:text-16-scale-924 font-[600] "} className={"h-50-scale-924-mob md:!h-45-scale-924"}
                rules={{
                 pattern: {
                    value: /^\d{10}$/,
                    message: 'Please Enter Valid Phone Number',
                  }
                }} errors={errors.phone_number} phone_number={"true"} />
            </div>
            {/* <div className="w-[100%] md:w-[47%]">
              <GlobalInput name="address" label="Address" control={control} labelStyles={"text-16-scale-mob md:text-16-scale-924 font-[600] "} className={"h-50-scale-924-mob md:!h-45-scale-924"}
                required={true} rules={{ required: "Address is required" }} errors={errors.address} />

            </div> */}
            <div className="w-[100%] md:w-[47%]">
              <GLobalSelect name="province" label="State" control={control} options={defaultProvinceOptions} onChange={(value) => handleChangeProvince(value.value)}
                labelStyles={"text-16-scale-mob md:text-16-scale-924 font-[600] "} className={"h-50-scale-mob md:!h-45-scale-924"} />
            </div>
            <div className="w-[100%] md:w-[47%]">
              <GLobalSelect name="city" label="City" control={control} options={defaultCitiesOptions}
                labelStyles={"text-16-scale-mob md:text-16-scale-924 font-[600] "}
                className={"h-50-scale-mob md:!h-45-scale-924"}
                controlStyles={"h-50-scale-mob md:!h-45-scale-924"}
                 />
            </div>

          </div>
          <p className="text-32-scale-mob md:text-22-scale-924 font-[600] ">Family Information</p>
          <div className="w-[100%] md:w-[47%]">
            <GLobalSelect name="family_type" label="Living situation" control={control} options={familytypeOptions}
              labelStyles={"text-16-scale-mob md:text-16-scale-924 font-[600] "} className={"h-50-scale-mob md:!h-45-scale-924"}  onChange={() => { setRefersh(!refresh); setValue("spouse_name", ""); setValue("spouse_age", ""); setValue("other_family_information", "") }} />
          </div>
          {/* {getValues("family_type")?getValues("family_type").value:"Spouse Information"} */}
          {getValues("family_type") && getValues("family_type").value !== "alone" && <><p className="text-16-scale-mob md:text-16-scale-924 font-[600] ">{getValues("family_type") && formatString(getValues("family_type").value)} Information</p>
            <div className="flex gap-x-[40px] flex-wrap">
              <div className="w-[100%] md:w-[47%]">
                <GlobalInput name="spouse_name" label="Name" control={control} labelStyles={"text-16-scale-mob md:text-16-scale-924 font-[600] "} className={"h-50-scale-924-mob md:!h-45-scale-924 "} />
              </div>
              <div className="w-[100%] md:w-[10%]">
                <GlobalInput name="spouse_age" label="Age" control={control} labelStyles={"text-16-scale-mob md:text-16-scale-924 font-[600] "} className={"h-50-scale-924-mob md:!h-45-scale-924 "} errors={errors.spouse_age}
                  max={99} type="number"
                  rules={{

                    pattern: {
                      value: /^\d{1,2}$/,
                      message: 'Please Enter Valid Age',
                    }
                  }}


                />
              </div>
            </div>
            <div>
              <GlobalTextArea name="other_family_information" label="Other Family Information" control={control} labelStyles={"text-16-scale-mob md:text-16-scale-924 font-[600] "} />
            </div></>}
          {/* <p className="text-32-scale-mob md:text-22-scale-924 font-[600]">Personal caregivers</p> */}
          {/* <ContactsForm control={control} arr={contactArr}/> */}
          {contactArr.map((val, index) => <ContactsForm key={val.id} control={control} errors={errors} index={index + 1} id={val.id} is_agreed={val.is_agreed} handleChangeAgrred={handleChangeCheckbox} relationshipOptions={relationshipOptions} handleContactDel={handleContactDel} />)}
          <div className='flex'>
            <button onClick={() => setContactArr([...contactArr, { id: Date.now().toString(), is_agreed: false, source: "application" }])} className="bg-black-100 text-white-100 text-14-scale-mob md:text-14-scale-924 font-[700] rounded-[10px] p-[10px] px-[15px]">Add another emergency contact</button>
          </div>

        </div>
      </div>
      <div className="pt-150-scale-mob md:pt-150-scale-924">
        <FormFooter onNextClick={methods.handleSubmit(onSubmit)} loading={loading} onClose={onCloseForm} />
      </div>
    </>
  )
}

export default React.memo(BasicInfo)