import React, { useState, useEffect } from 'react'
import GlobalInput from '../../components/GlobalInput';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import FormFooter from './FormFooter';
import { useSelector, useDispatch } from 'react-redux';
import { editUserDeatils, getUserDetails } from '../../redux/actions/usersactions';
import { formatString } from '../../methods';
import { toast } from 'react-toastify';
import { usePath } from '../../states/PathContext';

const SurgicalInfo = () => {
    //button loader
    const {path} =usePath()
    const [loading, setLoading] = useState(false)
    const formData = useSelector((state) => state.users.user_details);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { id } = params;
    const [state, setState] = useState({})
    const methods = useForm({ mode: "all" });

    const { control, formState: { errors }, setValue, getValues } = methods;
    const cheboxlabels = ["Knee Replacement", "Cataract", "Pacemaker / valve Replacement", "Hernia", "None", "Others"]
    const pysicalDisabilityLabels = ["Hearing Impairment", "Visual Impairment", "Need For Walking Aid", "Bed Ridden", "None", "Others"]
    const onSubmit = async (data) => {
        // if (state.surgical_history&&state.surgical_history.length > 0 &&state.physical_disabilities&&state.physical_disabilities.length > 0) {
            setLoading(true)
           const reqData= formattedData()
            try {
                await dispatch(editUserDeatils(id, reqData && reqData));
                await dispatch(getUserDetails(id))
                // navigate(`/user/add/${id}/hobbiesinfo`,{state:{from:path}})
                navigate(`/user/add/${id}/summary`,{state:{from:path}})
            }
            catch (err) {
                console.log(err.message)
                toast.error(err.message)
            }
            setLoading(false)
        // } else {
        //     toast.error("Please select surgical history and physical disability")
        // }
    }
    const handleCheckboxChange = (event, name) => {
        const { value } = event.target;

        const findValue = formData?.[name]?.find(val => {
            if (name === "surgical_history") {
                return val.surgery === value.toLowerCase().replace(/ /g, "_")
            } else {
                return val.disability === value.toLowerCase().replace(/ /g, "_")
            }
        });

        const checked = { name: value };
        if (findValue) {
            checked.id = findValue.id
        }
        if (event.target.type === "checkbox") {
            setState({
                ...state, [name]: state[name] ? state[name].some(obj => obj.name === value) ? state[name].filter(val => val.name !== value) : [...state[name], checked] : [checked]
            })
            //    setState({...state,[name]:state[name]?state[name].includes(value)?state[name].filter(val=>val!==value):[...state[name],value]:[value]
            // })
        }
    };
    //defaultvalues in form
    useEffect(() => {

        if (formData && formData.id === parseInt(id)) {
            const { surgical_history, physical_disabilities } = formData;
            let reqSurgicalHistory = []
            const otherDetails = surgical_history.find(val => val.surgery === "others") || {};
            const otherDisabilities = physical_disabilities.find(val => val.disability === "others") || {};
                      for (let val of surgical_history) {
                val.surgery && reqSurgicalHistory.push({ name: val.surgery === "pacemaker_valve_replacement" ? "Pacemaker / valve Replacement" : val.surgery === "no_surgery" ? "None" : formatString(val.surgery), id: val.id });
                val.surgery && val.surgery === "pacemaker_valve_replacement" ? setValue("Pacemaker / valve Replacement", val.year) : setValue(formatString(val.surgery), val.year);
            }
            const reqPhysicalDisabilitises = physical_disabilities ? physical_disabilities.map(val => ({ name: val.disability === "no_disability" ? "None" : formatString(val.disability), id: val.id })) : []
            setValue("other_details", otherDetails.other_details)
            setValue("other_disabilities", otherDisabilities.other_details)

            setState({ ...state, surgical_history: reqSurgicalHistory, physical_disabilities: reqPhysicalDisabilitises })

        }

    }, [formData])
    const onCloseForm=async()=>{
        const reqData= formattedData();           
 
         try {
             await dispatch(editUserDeatils(id, reqData));
             await dispatch(getUserDetails(id))
             path==="/users"? navigate(`/users`):navigate("/usermanagement")
         } catch (err) {
             console.log(err);
             toast.error(err.message)
         }
     }
     const handleBackClick=async()=>{
         const reqData= formattedData();  
         try {
             await dispatch(editUserDeatils(id, reqData));
             await dispatch(getUserDetails(id))
             navigate(-1)
         } catch (err) {
             console.log(err);
             toast.error(err.message)
         }
     }
     //format data for backend
    const formattedData=()=>{
        const surgical_history = state.surgical_history && state.surgical_history.map(val => {
            const other_details = val.name === "Others" ? getValues("other_details") : null;

            const result = {
                surgery: val.name === "Pacemaker / valve Replacement" ? "pacemaker_valve_replacement" : val.name === "None" ? "no_surgery" : val.name.toLowerCase().replace(/ /g, "_"),
            };
            if (other_details != null) {
                result.other_details = other_details;
            } else if (getValues(val.name)) {
                result.year = parseInt(getValues(val.name))
            } else {
                result.year = null
            }
            if (val.id) {
                result.id = val.id
            }
            return result
        })
        const physical_disabilities = state.physical_disabilities && state.physical_disabilities.map(val => {
            const other_details = val.name === "Others" ? getValues("other_disabilities") : null;
            const result = {
                disability: val.name === "None" ? "no_disability" : val.name.toLowerCase().replace(/ /g, "_"),
            };
            if (other_details != null) {
                result.other_details = other_details;
            }
            if (val.id) {
                result.id = val.id
            }
            return result
        })
        
        let reqData = {physical_disabilities,surgical_history}
        // if (physical_disabilities.length) {
        //     reqData.physical_disabilities = physical_disabilities
        // }
        // if (surgical_history.length) {
        //     reqData.surgical_history = surgical_history
        // }
        return reqData
    }
 

    return (
        <>
            <div className="px-19-scale-mob lg:px-19-scale-924 pt-50-scale-mob lg:pt-40-scale-924 w-[100%]">

                <div className="px-20-scale-mob lg:px-41-scale-924 w-[100%] card pt-20-scale-mob lg:pt-20-scale-924 pb-30-scale-mob lg:pb-30-scale-924 flex flex-col lg:w-[80%]  mx-auto">
                    <p className="text-20-scale-mob lg:text-20-scale-924 font-[600] ">Surgical history?
                    {/* <span className="text-[#FF4664] pl-[5px]">*</span> */}
                    </p>
                    <div className="flex flex-wrap gap-y-[25px] checkbox-group" onChange={(e) => handleCheckboxChange(e, "surgical_history")}>
                        {cheboxlabels.map(val =>
                            <div className='flex gap-[10px] w-[50%] md:w-[30%] items-center  '>
                                <input type="checkbox" name={val} value={val} className="w-[30px] h-[30px] custom-checkbox" checked={state.surgical_history && state.surgical_history.some(value => value.name === val)} />
                                <label for="medical-info" className="text-14-scale-mob lg:text-14-scale-924 " >{val}</label>
                            </div>
                        )}

                    </div>
                    {state.surgical_history && state.surgical_history.some(value => value.name === "Others") && <div className="lg:w-[85%]">
                        <GlobalInput name="other_details" label="Other details" control={control} labelStyles={"text-16-scale-mob lg:text-16-scale-924 font-[600]"} className={"h-40-scale-924-mob lg:!h-40-scale-924"} required={true} rules={{ required: "other conditions are required" }} errors={errors.other_conditions} />
                    </div>}
                    {/* <p>When was the Knee replacement  surgery done? (Optional)</p> */}
                    {state.surgical_history && state.surgical_history.map(val => {
                        if (val.name === "None" || val.name === "Others") return null
                        else {
                            return (
                                <div className="flex gap-x-[10px]">
                                    <p className="text-16-scale-mob lg:text-16-scale-924 w-[50%]">When Was the {val.name} surgery done?(optional)</p>
                                    <div className="flex gap-x-[10px] formData items-center">
                                        <div className="w-[70px] ">
                                            <GlobalInput control={control} name={val.name} type="number" />

                                        </div>
                                        <p className="text-16-scale-mob lg:text-16-scale-924 ">years back</p>
                                    </div>
                                </div>)
                        }
                    })}
                    <hr className={"bg-[black] text-[black] h-[3px] mt-[10px]"} />
                    {/*Physical Disablilities */}
                    <p className="text-20-scale-mob lg:text-20-scale-924 font-[600] ">Physical disabilities ?
                    {/* <span className="text-[#FF4664] pl-[5px]">*</span> */}
                    </p>
                    <div className="flex flex-wrap gap-y-[25px] checkbox-group" onChange={(e) => handleCheckboxChange(e, "physical_disabilities")}>
                        {pysicalDisabilityLabels.map(val =>
                            <div className='flex gap-[10px] md:w-[30%] items-center '>
                                <input type="checkbox" name={val} value={val} className="w-[30px] h-[30px] custom-checkbox" checked={state.physical_disabilities && state.physical_disabilities.some(value => value.name === val)} />
                                <label for="medical-info" className="text-14-scale-mob lg:text-14-scale-924 " >{val}</label>
                            </div>
                        )}

                    </div>
                    {state.physical_disabilities && state.physical_disabilities.some(value => value.name === "Others") && <div className="lg:w-[85%]">
                        <GlobalInput name="other_disabilities" label="Other details" control={control} labelStyles={"text-16-scale-mob lg:text-16-scale-924 font-[600]"} className={"h-40-scale-924-mob lg:!h-40-scale-924"} required={true} rules={{ required: "other conditions are required" }} errors={errors.other_conditions} />
                    </div>}

                </div>
            </div>
            {/* <div className="pt-150-scale-mob lg:pt-100-scale-924">
      <FormFooter onNextClick={methods.handleSubmit(onSubmit)} back={true}/>
      </div> */}
            <div className="pt-150-scale-mob lg:pt-100-scale-924">
                <FormFooter onNextClick={methods.handleSubmit(onSubmit)} back={true} loading={loading} onClose={onCloseForm} onBack={handleBackClick}/>
            </div>
        </>
    )
}

export default SurgicalInfo